export default [
  {
    path: "/",
    redirect: "/home",
  },
  {
    name: "首页",
    path: "/home",
    component: () => import("../pages/home/index"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "用户登录",
    path: "/signin",
    component: () => import("../pages/sign/in"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "用户注册",
    path: "/signup",
    component: () => import("../pages/sign/up"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "忘记密码",
    path: "/forgot",
    component: () => import("../pages/sign/forgot"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "搜索列表",
    path: "/search",
    component: () => import("../pages/search/index"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "商品详情",
    path: "/goods/view/:id",
    component: () => import("../pages/goods/view"),
    meta: {
      keepAlive: false,
    },
  },
  // 购物车及结算部分
  {
    name: "购物车",
    path: "/cart",
    component: () => import("../pages/cart/index"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "STEP2 订单确认",
    path: "/cart/submit",
    component: () => import("../pages/cart/submit"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "STEP3 订单支付",
    path: "/cart/pay",
    component: () => import("../pages/cart/pay"),
    meta: {
      keepAlive: false,
    },
  },
  // 个人中心部分
  {
    name: "会员中心",
    path: "/center",
    component: () => import("../pages/center/index"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "订单列表 - 会员",
    path: "/center/orders",
    component: () => import("../pages/center/orders"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "订单详情 - 会员",
    path: "/center/order",
    component: () => import("../pages/center/order"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "我的收藏 - 会员",
    path: "/center/favor",
    component: () => import("../pages/center/favor"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "个人资料 - 会员",
    path: "/center/info",
    component: () => import("../pages/center/info"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "账户安全 - 会员",
    path: "/center/safe",
    component: () => import("../pages/center/safe"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "修改密码 - 账户安全",
    path: "/center/safe/password",
    component: () => import("../pages/center/safe_password"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "邮箱验证 - 账户安全",
    path: "/center/safe/email",
    component: () => import("../pages/center/safe_email"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "手机验证 - 账户安全",
    path: "/center/safe/mobile",
    component: () => import("../pages/center/safe_mobile"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "我的积分",
    path: "/center/points",
    component: () => import("../pages/center/points"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "收货地址",
    path: "/center/address",
    component: () => import("../pages/center/address"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "我的评论",
    path: "/center/reviews",
    component: () => import("../pages/center/reviews"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "我的评论 - 查看评价",
    path: "/center/reviews/view",
    component: () => import("../pages/center/reviews_view"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "我的评论 - 订单评价",
    path: "/center/reviews/edit",
    component: () => import("../pages/center/reviews_edit"),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: "物流详情",
    path: "/center/ship",
    component: () => import("../pages/center/ship"),
    meta: {
      keepAlive: false,
    },
  },
];
