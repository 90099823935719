<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />
    <alert />
    <toast />
  </div>
</template>

<script>
import alert from "./components/common/alert.vue";
import toast from "./components/common/toast.vue";

export default {
  name: "App",
  components: {
    toast,
    alert,
  },
};
</script>

<style>
@import url("assets/less/html.less");
</style>
