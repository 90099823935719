import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";

const router = createRouter({
  mode: "history",
  base: "/",
  history: createWebHistory(),
  routes: routes,
});

export default router;
