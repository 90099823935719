<template>
  <div
    class="toast"
    v-if="state.show !== false"
    :class="{ show: state.show === true }"
  >
    <span>{{ state.message }}</span>
  </div>
</template>

<script>
import store from "../../store/index";
import { reactive, computed, watch } from "vue";

export default {
  props: [],

  setup(props) {
    const state = reactive({
      timer: 0,
      show: false,
      message: "",
      msg: computed(() => {
        return store.getters.toast;
      }),
    });

    watch(
      () => state.msg,
      (newVal) => {
        if (newVal === null) {
          state.show = null;
          setTimeout(() => {
            state.show = false;
            state.message = "";
          }, 100);
        } else {
          state.message = newVal;
          state.timer = 3;
          if (state.show === false) {
            state.show = null;
            setTimeout(() => {
              state.show = true;
              doTimer();
            }, 100);
          }
        }
      }
    );

    const doTimer = () => {
      if (state.timer <= 1) {
        store.dispatch("toast", null);
        state.timer = 0;
      } else {
        state.timer--;
        setTimeout(() => {
          doTimer();
        }, 1000);
      }
    };

    return { state, props };
  },
};
</script>

<style lang="less" scoped>
.toast {
  position: fixed;
  left: 0;
  bottom: 0px;
  height: 150px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 99999;

  span {
    position: relative;
    display: inline-block;
    max-width: 60%;
    padding: 20px 30px;
    border-radius: 10px;
    line-height: 30px;
    font-size: 24px;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.8);
    top: 160px;
    opacity: 0;
    transition: all 0.3s;
  }

  &.show {
    span {
      top: 0px;
      opacity: 1;
    }
  }
}
</style>
