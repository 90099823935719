import { createStore } from "vuex";

let token = null;
let customerName = null;

if (sessionStorage.getItem("token")) {
  token = sessionStorage.getItem("token");
  customerName = sessionStorage.getItem("customerName");
}

export default createStore({
  //数据存储属性
  state: {
    token: token,
    customerName: customerName,
    alert: null,
    toast: null,
    updateCart: false,
  },
  //方法属性
  mutations: {
    setToken: (state, data) => {
      if (data === null) {
        state.token = null;
        state.customerName = null;
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("customerName");
      } else {
        state.token = data.token;
        state.customerName = data.customerName;
        sessionStorage.setItem("token", state.token);
        sessionStorage.setItem("customerName", state.customerName);
      }
    },

    showAlert: (state, alert) => {
      // console.log(alert);
      state.alert = alert;
    },

    showToast: (state, message) => {
      state.toast = message;
    },

    updateCart: (state, stat) => {
      state.updateCart = stat;
    },
  },
  //异步属性
  actions: {
    alert(context, alert) {
      if (typeof alert == "string")
        alert = {
          timer: 5,
          icon: "error",
          msg: alert,
        };

      //console.log(alert);
      context.commit("showAlert", alert);
    },

    toast(context, msg) {
      context.commit("showToast", msg);
    },

    setUserData(context, data) {
      context.commit("setToken", data);
    },

    clearUserData(context) {
      context.commit("setToken", null);
    },

    needUpdateCart(context) {
      context.commit("updateCart", true);
    },

    updatedCart(context) {
      context.commit("updateCart", false);
    },
  },
  //计算属性
  getters: {
    customerName(state) {
      return state.customerName || null;
    },

    updateCart(state) {
      return state.updateCart;
    },

    alert(state) {
      return state.alert;
    },

    toast(state) {
      return state.toast;
    },
  },
  modules: {},
});
