<template>
  <div class="modal" v-if="state.data" :class="{ show: state.show }">
    <div class="container alert">
      <div class="mbody">
        <div class="icon" :class="state.data.icon"></div>
        <div class="msg">
          {{ state.data.msg }}<small>{{ state.data.info }}</small>
        </div>
      </div>
      <div class="mfoot">
        <span class="timer" v-if="state.timer > 0"
          >{{ state.timer }}秒后自动关闭</span
        >
        <a class="submit" @click="onConfirm">我知道了</a>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store/index";
import { reactive, computed, watch } from "vue";

export default {
  props: [],

  setup(props) {
    const state = reactive({
      timer: 0,
      show: false,
      data: computed(() => {
        // console.log(store.getters.alert);
        return store.getters.alert;
      }),
    });

    watch(
      () => state.show,
      (newdata) => {
        if (newdata) {
          document.body.classList.add("modal_open");
        } else {
          document.body.classList.remove("modal_open");
        }
      }
    );

    watch(
      () => state.data,
      (newVal) => {
        console.log(newVal);
        if (newVal === false) {
          state.show = false;
        } else {
          state.show = true;
          state.timer = newVal.timer ? newVal.timer : 0;
          if (state.timer) {
            doTimer();
          }
        }
      }
    );

    const doTimer = () => {
      setTimeout(() => {
        if (state.timer <= 1) {
          store.dispatch("alert", false);
          state.timer = 0;
        } else {
          state.timer--;
          doTimer();
        }
      }, 1000);
    };

    const onConfirm = () => {
      store.dispatch("alert", false);
    };

    return { state, props, onConfirm };
  },
};
</script>

<style lang="less" scoped>
@import "../modal/modal.less";

.modal .container.alert {
  width: 430px !important;

  .mbody {
    text-align: left;
    padding: 28px;

    .icon {
      position: absolute;
      top: 28px;
      left: 28px;
      display: block;
      width: 30px;
      height: 30px;
      background: transparent no-repeat center;
      background-size: contain;
      margin: 0 auto 16px;

      &.none {
        display: none;
      }

      &.error {
        background-image: url("../../assets/img/icon_error.svg");
      }

      &.warning {
        background-image: url("../../assets/img/icon_warning.svg");
      }

      &.success {
        background-image: url("../../assets/img/icon_success.svg");
      }

      &.question {
        background-image: url("../../assets/img/icon_question.svg");
      }
    }

    .msg {
      padding-left: 45px;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;

      small {
        display: block;
        font-size: 13px;
        color: #8c8c8c;
        line-height: 20px;
        word-break: break-all;

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .mfoot {
    border-top: 0;
    padding-bottom: 20px;

    .timer {
      display: inline-block;
      vertical-align: top;
      height: 34px;
      line-height: 34px;
      font-size: 14px;
      color: #cccccc;
    }
  }
}
</style>
