import { createApp } from "vue";
import router from "./route/index";
import store from "./store/index";
import App from "./App.vue";

router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = to.name + " - 云方 CLOUD³MALL";
  }
  next();
});

const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
